import { Loader } from '@onsaui'
import cx from 'clsx'
import dayjs from 'dayjs'
import { useState } from 'react'

import useTasksStatistics from '@/modules/task/hooks/useTasksStatistics'

import RangePicker from '../RangePicker/RangePicker'
import TasksStatisticsView from './TasksStatisticsView'

const TasksStatisticsWidget: React.FC<{ className?: string }> = ({ className }) => {
  const [fromDate, setFromDate] = useState<number>(dayjs().subtract(1, 'month').valueOf())
  const [toDate, setToDate] = useState<number>(dayjs().valueOf())
  const handleDateRangeChange = (from: number, to: number) => {
    setFromDate(from)
    setToDate(to)
  }

  const { data: tasksStatistics, isPending, isError, error } = useTasksStatistics(fromDate, toDate)

  let body
  if (isPending) {
    body = (
      <div className="flex h-[400px] flex-col items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div>
        <p className="mb-1 text-danger">Unable to load tasks statistics...</p>
        {error?.message && <p className="text-sm text-danger">{error?.message}</p>}
      </div>
    )
  } else {
    body = <TasksStatisticsView tasksStatistics={tasksStatistics} />
  }

  return (
    <div className={cx('', className)}>
      <h2 className="mb-8">Tasks Status</h2>

      <RangePicker
        className="mb-8"
        from={fromDate}
        to={toDate}
        onRangeChange={handleDateRangeChange}
      />

      {body}
    </div>
  )
}

export default TasksStatisticsWidget

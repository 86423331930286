import { IconExit } from '@onsaui/icons'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import cx from 'clsx'
import { Link } from 'react-router-dom'

import { ReactComponent as RgLogo } from '@/assets/rg_logo.svg'
import useUserMe from '@/modules/user/hooks/useUserMe'

const ProfileMenu: React.FC<{ className?: string }> = ({ className }) => {
  const { data: userMe, isPending, isError } = useUserMe()

  const menuItemClass =
    'flex h-[100px] flex-row items-center px-5 outline-none hover:text-primary focus-visible:text-primary gap-3 w-full text-left'

  const triggerAvatar = (
    <button
      type="button"
      className={cx(
        'flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary-surface outline-none',
        className,
      )}
      aria-label="Profile menu"
    >
      <RgLogo className="h-5 w-5" />
    </button>
  )

  if (isPending) {
    return triggerAvatar
  }

  if (isError) {
    return (
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>{triggerAvatar}</DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          className="min-w-[320px] rounded-3xl bg-level1 px-0 py-10 shadow-lg"
          side="bottom"
          align="end"
          sideOffset={24}
        >
          <DropdownMenuPrimitive.Label asChild>
            <div className="flex flex-col items-center">
              <p className="text-sm text-danger">Unable to load profile...</p>
            </div>
          </DropdownMenuPrimitive.Label>
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    )
  }

  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild>{triggerAvatar}</DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Content
        className="min-w-[320px] rounded-3xl bg-level1 px-0 pb-3 pt-10 shadow-2xl"
        side="bottom"
        align="end"
        sideOffset={24}
      >
        <DropdownMenuPrimitive.Label asChild>
          <div className="flex flex-col items-center">
            <RgLogo className="mb-4 h-[64px] w-[64px]" />
            <p className="text-sm text-secondary">{userMe.email}</p>
          </div>
        </DropdownMenuPrimitive.Label>

        <DropdownMenuPrimitive.Item asChild>
          <Link to="/sign-out" className={menuItemClass}>
            <IconExit />
            <span>Sign Out</span>
          </Link>
        </DropdownMenuPrimitive.Item>
        {/* <hr className="border-disabled" /> */}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Root>
  )
}
export default ProfileMenu

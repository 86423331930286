import { Button, Input, Modal } from '@onsaui'
import { useState } from 'react'

const TestUIPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <main className="bg-level-1 h-full w-full p-4">
      <section className="mb-8">
        <h2 className="mb-2 font-semibold">Typography</h2>

        <h1>Header 1</h1>
        <h2>Header 2</h2>
        <h3>Header 3</h3>
        <h4>Header 4</h4>
        <p>Body text</p>
        <p className="text-sm">Small text</p>
        <p className="text-xs">Extra small text</p>
      </section>

      <section className="mb-8">
        <h2 className="mb-2 font-semibold">Button</h2>

        <Button variant="primary">Button</Button>
        <Button variant="primary" isDisabled>
          Button
        </Button>
        <Button variant="danger">Button</Button>
        <Button variant="outlined">Button</Button>
        <Button variant="outlined" isDisabled>
          Button
        </Button>
        <Button variant="ghost">Button</Button>
        <Button variant="ghost" isDisabled>
          Button
        </Button>

        <Button asChild>
          <a href="https://google.com" target="_blank" rel="noreferrer">
            Link to google
          </a>
        </Button>
      </section>

      <section className="mb-8">
        <h2 className="mb-2 font-semibold">Input</h2>

        <div className="flex flex-col gap-2">
          <Input placeholder="Input" id="test-input-1" name="test-input-1" />

          <Input placeholder="Input" id="test-input-2" name="test-input-2" isDisabled />
          <Input
            placeholder="Input"
            id="test-input-3"
            name="test-input-3"
            isError
            errorMessage="Something went wrong"
            shakeOnError
          />
          <Input placeholder="Input" id="test-input-4" name="test-input-4" isError />
        </div>
      </section>

      <section>
        <h2 className="mb-2 font-semibold">Modal</h2>

        <Button onClick={() => setIsModalOpen(true)}>Open modal</Button>

        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
          }}
        >
          Hello world
        </Modal>
      </section>
    </main>
  )
}

export default TestUIPage

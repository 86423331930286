import { Navigate } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'

const HomePage: React.FC = () => {
  const { isAuth } = useAuth()

  if (!isAuth) {
    return <Navigate to="/sign-in" replace />
  }

  return <Navigate to="/dashboard" replace />
}

export default HomePage

import { UserMe } from '@/modules/user/model'
import { ApiService } from '@/services/api'

import { userMeAdapter } from './userApiAdapters'
import { UserMeResponse } from './userApiInterfaces'

export const getUserMe = async (): Promise<UserMe> => {
  const response = await ApiService.workspaceInstance.get<UserMeResponse>('admin/me')

  return userMeAdapter(response.data)
}

import cx from 'clsx'

import useBaseStatistics from '@/modules/task/hooks/useBaseStatistics'

const BaseStatisticsWidget: React.FC<{ className?: string }> = ({ className }) => {
  const { data: baseStatistics, isPending, isError, error } = useBaseStatistics()

  let body
  if (isPending) {
    body = (
      <>
        <div>
          <span className="mr-1">Pending Tasks:</span>
          <span className="inline-block h-4 w-6 animate-pulse rounded-sm bg-slate-300 align-middle" />
        </div>
        <div>
          <span className="mr-1">Median Time:</span>
          <span className="inline-block h-4 w-12 animate-pulse rounded-sm bg-slate-300 align-middle" />
        </div>
        <div>
          <span className="mr-1">Avg. score:</span>
          <span className="inline-block h-4 w-12 animate-pulse rounded-sm bg-slate-300 align-middle" />
        </div>
      </>
    )
  } else if (isError) {
    body = (
      <>
        <p className="mb-1 text-danger">Unable to load base statistics...</p>
        {error?.message && <p className="text-sm text-danger">{error?.message}</p>}
      </>
    )
  } else {
    body = (
      <>
        <div>
          <span className="mr-1">Pending Tasks:</span>
          <span className="font-bold">{baseStatistics.pendingTasksCount}</span>
        </div>
        <div>
          <span className="mr-1">Median Time:</span>
          <span className="font-bold">{baseStatistics.medianCompletionTimeMinutes} min</span>
        </div>
        <div className={cx({ 'text-disabled': !baseStatistics.medianScore })}>
          <span className="mr-1">Median score:</span>
          <span className="font-bold">{baseStatistics.medianScore || '-'}</span>
        </div>
      </>
    )
  }

  return (
    <div
      className={cx(
        'inline-flex flex-row items-center gap-8 rounded-xl bg-primary-surface px-5 py-3',
        className,
      )}
    >
      {body}
    </div>
  )
}

export default BaseStatisticsWidget

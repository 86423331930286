import { ApiService } from '@/services/api'

import { baseStatisticsAdapter, tasksStatisticsAdapter } from './taskApiAdapters'
import { BaseStatisticsResponse, TasksStatisticsResponse } from './taskApiInterfaces'

export const getTasksStatistics = async (fromDate: number, toDate: number) => {
  const response = await ApiService.apiInstance.get<TasksStatisticsResponse>(
    'admin/tasks/statistics',
    { params: { from: fromDate, to: toDate } },
  )

  return tasksStatisticsAdapter(response.data)
}

export const getBaseStatistics = async () => {
  const response = await ApiService.apiInstance.get<BaseStatisticsResponse>(
    'admin/tasks/immediate-statistics',
  )

  return baseStatisticsAdapter(response.data)
}

import clsx from 'clsx'
import React from 'react'

import cm from './Loader.module.css'

const Loader: React.FC<{ className?: string; sm?: boolean; md?: boolean }> = ({
  className,
  sm,
  md,
}) => {
  return (
    <div className={clsx(cm.wrapper, { [cm.sizeSm]: sm, [cm.sizeMd]: md }, className)}>
      <div className={cm.root}>
        <div className={cm.dot} />
      </div>
    </div>
  )
}

export default Loader

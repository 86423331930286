import cx from 'clsx'

const StatSection: React.FC<{
  title: React.ReactNode
  total: React.ReactNode
  footerStats: Array<{ label: React.ReactNode; count: React.ReactNode }>
  score?: number
  className?: string
}> = ({ title, total, footerStats, score, className }) => {
  return (
    <section className={cx('rounded-3xl border border-disabled p-5 text-center', className)}>
      <h3 className="mb-6 text-base text-primary">{title}</h3>
      <p className="mb-6 text-4xl font-bold">{total}</p>
      <div className="flex flex-row items-center justify-center gap-4">
        {footerStats.map((stat, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="">
              <span className="mr-1">{stat.label}</span>
              <span className="font-bold">{stat.count}</span>
            </div>
          )
        })}
      </div>

      <div
        className={cx(
          'mt-4 inline-flex flex-row items-center gap-2 rounded-xl bg-primary-surface px-5 py-3',
          { 'text-disabled': !score },
        )}
      >
        <span>Median score:</span>
        <span className="font-bold">{score || '-'}</span>
      </div>
    </section>
  )
}
export default StatSection

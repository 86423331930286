import { create } from 'zustand'

export interface AuthStore {
  token: string | null
  userId: string | null
  sessionId: string | null

  isSignInModalOpen: boolean
  isSignInToContinueModalOpen: boolean
}

export const useAuthStore = create<AuthStore>((set) => ({
  token: null,
  userId: null,
  sessionId: null,

  isSignInModalOpen: false,
  isSignInToContinueModalOpen: false,
}))

export const openSignInModal = () => {
  useAuthStore.setState({ isSignInModalOpen: true })
}
export const closeSignInModal = () => {
  useAuthStore.setState({ isSignInModalOpen: false })
}

export const openSignInToContinueModal = () => {
  useAuthStore.setState({ isSignInToContinueModalOpen: true })
}
export const closeSignInToContinueModal = () => {
  useAuthStore.setState({ isSignInToContinueModalOpen: false })
}

import { UserMe } from '@/modules/user/model'

import { UserMeResponse } from './userApiInterfaces'

export const userMeAdapter = (userResponse: UserMeResponse): UserMe => {
  const user: UserMe = {
    id: userResponse.id,
    email: userResponse.email,
    role: userResponse.role,
  }

  return user
}

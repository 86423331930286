import clsx from 'clsx'
import toast, { Toaster } from 'react-hot-toast'

// import { IconCheck, IconError } from '../../icons'
import cm from './Notifications.module.css'

export const notify = (config: {
  message: string
  variant: 'success' | 'error'
  duration?: number
}) => {
  const { message, variant, duration = 2000 } = config

  toast.custom(
    (t) => {
      const icon = null

      return (
        <div
          className={clsx('flex flex-row items-center gap-3 rounded-xl py-4 pl-4 pr-4 shadow-xl', {
            [cm.showToast]: t.visible,
            [cm.hideToast]: !t.visible,

            'bg-danger text-white': variant === 'error',
            'bg-success text-white': variant === 'success',
          })}
        >
          {icon}
          <div>
            <p className="whitespace-pre-wrap break-words">{message}</p>
          </div>
        </div>
      )
    },
    { duration },
  )
}

export const NotificationsRoot: React.FC = () => {
  return <Toaster position="bottom-center" containerClassName={cm.notificationsContainer} />
}

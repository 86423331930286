import { NotificationsRoot } from '@onsaui'
import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'

import AuthGuard from '@/modules/auth/components/AuthGuard/AuthGuard'
import AuthModal from '@/modules/auth/components/AuthModal/AuthModal'
import AuthRedirectPage from '@/pages/auth-redirect/AuthRedirectPage'
import DashboardPage from '@/pages/dashboard/DashboardPage'
import HomePage from '@/pages/home/HomePage'
import SignInPage from '@/pages/sign-in/SignInPage'
import SignOutPage from '@/pages/sign-out/SignOutPage'
import TestUIPage from '@/pages/test-ui/TestUIPage'
import { QueryService } from '@/services/query'

const Root: React.FC = () => {
  return (
    <QueryClientProvider client={QueryService.getClient()}>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <>
                <NotificationsRoot />

                <AuthModal />

                <Outlet />
              </>
            }
          >
            <Route path="auth" element={<AuthRedirectPage />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="sign-out" element={<SignOutPage />} />

            <Route path="test-ui" element={<TestUIPage />} />

            <Route path="/" element={<HomePage />} />

            <Route
              path="dashboard"
              element={
                <AuthGuard>
                  <DashboardPage />
                </AuthGuard>
              }
            />
          </Route>

          <Route path="*" element={<div>Page not found...</div>} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default Root

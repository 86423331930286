import { isAxiosError } from 'axios'

const getErrorMessage = (e: unknown) => {
  if (isAxiosError(e)) {
    return e.response?.data?.message || e.message
  }

  if (e instanceof Error) {
    return e.message
  }

  return String(e)
}

export default getErrorMessage

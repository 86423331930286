import './index.css'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'

import { authActions } from '@/modules/auth'
import { ApiService } from '@/services/api'
import { QueryService } from '@/services/query'

import Root from './Root'

dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(timezone)
dayjs.extend(isBetween)

QueryService.init({
  onError: (error) => {
    console.error('Query error', error)
  },
})

authActions.reviewAuth()

ApiService.init({
  getSessionId: authActions.getSessionId,
  getAuthToken: authActions.getAuthToken,
  getWorkspaceId: () => {
    return window.location.pathname.split('/')[1] ?? null
  },
  onTokenInvalid: authActions.dropAuth,
})

function App() {
  return <Root />
}

export default App

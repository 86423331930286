import { cva, type VariantProps } from 'class-variance-authority'
import cx from 'clsx'
import React, { useEffect } from 'react'

import { useShakeAnim } from '../../hooks/useShakeAnim/useShakeAnim'

const inputVariants = cva(
  'whitespace-nowrap transition-all h-[48px] px-4 text-base outline-none w-full',
  {
    variants: {
      isDisabled: {
        true: 'cursor-not-allowed',
        false: '',
      },
      isError: {
        true: '',
        false: '',
      },
      variant: {
        default: 'bg-transparent rounded-[10px] border',
      },
    },
    compoundVariants: [
      {
        variant: ['default'],
        isDisabled: false,
        isError: false,
        className: 'border-default text-default focus:border-primary',
      },
      {
        variant: ['default'],
        isDisabled: true,
        isError: false,
        className: 'border-disabled text-disabled placeholder:text-disabled',
      },
      {
        variant: ['default'],
        isError: true,
        className: 'border-danger/60 focus:border-danger',
      },
    ],
    defaultVariants: {
      variant: 'default',
      isDisabled: false,
      isError: false,
    },
  },
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  isError?: boolean
  errorMessage?: string
  shakeOnError?: boolean
  inputClassName?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      variant,
      size,
      id,
      name,
      inputClassName,
      isDisabled = false,
      isError = false,
      errorMessage,
      shakeOnError = false,
      ...props
    },
    ref,
  ) => {
    if (!id) {
      console.warn('Input component requires an id prop')
    }

    const [shakeClass, shakeIt] = useShakeAnim()
    useEffect(() => {
      if (isError && shakeOnError) {
        shakeIt()
      }
    }, [shakeOnError, isError, shakeIt])

    const errorProps = isError ? { 'aria-invalid': true, 'aria-describedby': `${id}-error` } : {}

    return (
      <div className={cx('align-top', shakeClass, className)}>
        <input
          ref={ref}
          id={id}
          name={name}
          className={cx(inputVariants({ variant, isDisabled, isError }), inputClassName)}
          disabled={isDisabled ?? undefined}
          {...errorProps}
          {...props}
        />
        {errorMessage && (
          <span id={`${id}-error`} className="mb-1 block text-xs text-danger">
            {errorMessage}
          </span>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }

import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { authActions } from '@/modules/auth'

const AuthRedirectPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const userId = searchParams.get('userId')
  const redirect = searchParams.get('redirect') || '/'

  const navigate = useNavigate()

  useEffect(() => {
    if (!token || !userId) {
      return navigate('/')
    }

    authActions.authViaToken(token, userId)

    navigate(redirect)
  }, [navigate, token, userId, redirect])

  return <div>Redirect...</div>
}

export default AuthRedirectPage

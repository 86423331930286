import cx from 'clsx'

import { TasksStatistics } from '@/modules/task/model'

import StatSection from './StatSection'

const TasksStatisticsView: React.FC<{ tasksStatistics: TasksStatistics; className?: string }> = ({
  tasksStatistics,
  className,
}) => {
  const { totalStats } = tasksStatistics
  const meetingMemoStats = tasksStatistics.statsByTaskType['meeting-memo']
  const meetingReportStats = tasksStatistics.statsByTaskType['meeting-report']

  const totalCount = totalStats.completed + totalStats.canceled + totalStats.failed
  const meetingMemoCount =
    meetingMemoStats.completed + meetingMemoStats.canceled + meetingMemoStats.failed
  const meetingReportCount =
    meetingReportStats.completed + meetingReportStats.canceled + meetingReportStats.failed

  return (
    <div className={cx('', className)}>
      <div className="max-w-[720px]">
        <StatSection
          className="mb-6"
          title="Tasks"
          total={totalCount}
          footerStats={[
            {
              label: 'Completed:',
              count: totalStats.completed,
            },
            {
              label: 'Canceled:',
              count: totalStats.canceled,
            },
            {
              label: 'Failed:',
              count: totalStats.failed,
            },
          ]}
          score={tasksStatistics.totalStats.medianScore ?? undefined}
        />

        <div className="grid grid-cols-2 gap-6 mobile:grid-cols-1">
          <StatSection
            title="Meeting Memo"
            total={meetingMemoCount}
            footerStats={[
              {
                label: 'Completed:',
                count: meetingMemoStats.completed,
              },
              {
                label: 'Canceled:',
                count: meetingMemoStats.canceled,
              },
              {
                label: 'Failed:',
                count: meetingMemoStats.failed,
              },
            ]}
            score={tasksStatistics.totalStats.medianScore ?? undefined}
          />
          <StatSection
            title="Meeting Report"
            total={meetingReportCount}
            footerStats={[
              {
                label: 'Completed:',
                count: meetingReportStats.completed,
              },
              {
                label: 'Canceled:',
                count: meetingReportStats.canceled,
              },
              {
                label: 'Failed:',
                count: meetingReportStats.failed,
              },
            ]}
            score={tasksStatistics.totalStats.medianScore ?? undefined}
          />
        </div>
      </div>
    </div>
  )
}

export default TasksStatisticsView

import { useQuery } from '@tanstack/react-query'

import { userApi } from '@/modules/user/api'

const useUserMe = () => {
  return useQuery({
    queryKey: ['user_me'],
    queryFn: () => {
      return userApi.getUserMe()
    },
  })
}

export default useUserMe

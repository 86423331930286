import React from 'react'

const AppLayout: React.FC<{
  header?: React.ReactNode
  body?: React.ReactNode
}> = ({ header, body }) => {
  return (
    <main className="flex min-h-full w-full flex-col bg-level1">
      <header className="flex h-[124px] shrink-0 flex-row items-center px-20 mobile:px-4">
        {header}
      </header>

      {body}
    </main>
  )
}

export default AppLayout

import { ApiService } from '@/services/api'

export const sendAuthEmail = async (email: string, redirect?: string): Promise<void> => {
  await ApiService.apiInstance.post('admin/login', {
    email,
    redirect,
  })
}

export const authViaCode = async (code: string) => {
  const response = await ApiService.apiInstance.post<{
    accessToken: string
    userId: string
  }>('admin/code', { code })

  return response.data
}

import { Button, Input, notify } from '@onsaui'
import React, { FormEvent, useState } from 'react'

import { authApi } from '@/modules/auth/api'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { SUPPORT_EMAIL } from '@/shared/constants/supportEmail'
import isValidEmail from '@/shared/helpers/isValidEmail'

const EmailStep: React.FC<{
  redirect?: string
  onEmailSent: (email: string) => void
}> = ({ redirect, onEmailSent }) => {
  const [email, setEmail] = useState('')
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const [isLoading, setIsLoading] = useState(false)

  const sendEmail = async (email: string) => {
    try {
      setIsLoading(true)
      await authApi.sendAuthEmail(email, redirect)

      setIsLoading(false)

      onEmailSent(email)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsLoading(false)
    }
  }

  const handleSignIn = (e: FormEvent) => {
    e.preventDefault()

    if (!isValidEmail(email)) {
      console.log('Please enter a valid email address.')
      // notify("Please enter a valid email address.", "error");
      // haptic.notification("warning");
      return
    }

    sendEmail(email)
  }

  return (
    <form className="flex max-w-[300px] flex-col gap-5 text-center" onSubmit={handleSignIn}>
      <h2 className="">Welcome</h2>
      <p>Enter your email below to receive a magic sign-in&nbsp;link.</p>

      <Input
        id="email"
        name="email"
        autoCapitalize="off"
        autoCorrect="off"
        className=""
        placeholder="adele@gmail.com"
        value={email}
        onChange={handleInputChange}
        isDisabled={isLoading}
      />

      <div className="flex flex-col">
        <Button type="submit" className="" isDisabled={isLoading} isLoading={isLoading}>
          Continue
        </Button>
        <Button variant="ghost" className="mt-3" asChild>
          <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
            Contact support
          </a>
        </Button>
      </div>
    </form>
  )
}

export default EmailStep

import axios, { isAxiosError } from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL as string

const apiInstance = axios.create({ baseURL: `${BASE_URL}/api` })
const workspaceInstance = axios.create({ baseURL: `${BASE_URL}/api` })

export const ApiService = {
  init: (serviceConfig: {
    getAuthToken: () => string | null
    getWorkspaceId: () => string | null
    getSessionId: () => string | null
    onTokenInvalid: () => void
  }) => {
    const { getAuthToken, onTokenInvalid, getWorkspaceId } = serviceConfig

    const authInterceptor = (config: any) => {
      const token = getAuthToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      const sessionId = serviceConfig.getSessionId()
      if (sessionId) {
        config.headers['X-Session-Id'] = sessionId
      }

      return config
    }

    const errorInterceptor = (error: any) => {
      if (isAxiosError(error)) {
        // ErrorService.captureAxiosError(error)

        if (error.response?.status === 401) {
          setTimeout(() => {
            onTokenInvalid()
          })
        }
      }

      return Promise.reject(error)
    }

    apiInstance.interceptors.request.use(authInterceptor)
    apiInstance.interceptors.response.use((response) => response, errorInterceptor)

    workspaceInstance.interceptors.request.use(authInterceptor)
    workspaceInstance.interceptors.response.use((response) => response, errorInterceptor)

    workspaceInstance.interceptors.request.use((config) => {
      const workspaceId = getWorkspaceId()
      if (workspaceId) {
        config.headers['X-Workspace-Id'] = workspaceId
      }

      return config
    })
  },

  apiInstance,
  workspaceInstance,
}

import { Input, notify, useShakeAnim } from '@onsaui'
import cx from 'clsx'
import dayjs from 'dayjs'

const RangePicker: React.FC<{
  from: number
  to: number
  onRangeChange: (from: number, to: number) => void
  className?: string
}> = ({ from, to, onRangeChange, className }) => {
  const handleShowPicker = (e: React.MouseEvent<HTMLInputElement>) => {
    if ('showPicker' in HTMLInputElement.prototype) {
      ;(e.target as HTMLInputElement).showPicker()
    }
  }

  const [shakeClassFrom, shakeItFrom] = useShakeAnim()
  const [shakeClassTo, shakeItTo] = useShakeAnim()

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const from = dayjs(e.target.value).startOf('day').valueOf()
    if (from > to) {
      notify({ message: 'Start date should be before the end date', variant: 'error' })
      shakeItFrom()
      return
    }

    onRangeChange(from, to)
  }
  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const to = dayjs(e.target.value).endOf('day').valueOf()
    if (to < from) {
      notify({ message: 'End date cannot be before the start date', variant: 'error' })
      shakeItTo()
      return
    }

    onRangeChange(from, to)
  }

  return (
    <div className={cx('flex flex-row items-center gap-2', className)}>
      <Input
        id="date-from"
        type="date"
        className="inline-block"
        inputClassName={cx('cursor-pointer', shakeClassFrom)}
        value={dayjs(from).format('YYYY-MM-DD')}
        onClick={handleShowPicker}
        onChange={handleFromChange}
        isError={Boolean(shakeClassFrom)}
      />

      <div className="h-1 w-6 border-b" />

      <Input
        id="date-to"
        type="date"
        className="inline-block"
        inputClassName={cx('cursor-pointer', shakeClassTo)}
        value={dayjs(to).format('YYYY-MM-DD')}
        onClick={handleShowPicker}
        onChange={handleToChange}
        isError={Boolean(shakeClassTo)}
      />
    </div>
  )
}
export default RangePicker

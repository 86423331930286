import { Modal } from '@onsaui'
import { useNavigate } from 'react-router-dom'

import { closeSignInModal, useAuthStore } from '@/modules/auth/store/authStore'

import SignInForm from '../SignInForm/SignInForm'

const AuthModal: React.FC = () => {
  const navigate = useNavigate()

  const { isSignInModalOpen } = useAuthStore()

  const handleClose = () => {
    closeSignInModal()
  }

  const handleSignInSuccess = () => {
    handleClose()
    navigate('/')
  }

  return (
    <Modal isOpen={isSignInModalOpen} onClose={handleClose}>
      <SignInForm onSignInSuccess={handleSignInSuccess} />
    </Modal>
  )
}
export default AuthModal

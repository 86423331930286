import { useQuery } from '@tanstack/react-query'

import { taskApi } from '@/modules/task/api'

const useBaseStatistics = () => {
  return useQuery({
    queryKey: ['base_statistics'],
    queryFn: () => {
      return taskApi.getBaseStatistics()
    },

    refetchInterval: 1000 * 10,
  })
}

export default useBaseStatistics

import { Button, notify } from '@onsaui'
import React from 'react'

import { authActions } from '@/modules/auth'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

import CodeInput from '../CodeInput/CodeInput'

const CodeStep: React.FC<{
  email: string
  onBack: () => void
  onSignIn?: () => void
}> = ({ email, onBack, onSignIn }) => {
  const handleCodeSubmit = async (code: string) => {
    try {
      await authActions.authViaCode(code)

      onSignIn?.()
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    }
  }

  return (
    <div className="flex flex-col gap-5 text-center">
      <h2 className="">Welcome</h2>
      <p>
        We emailed a code and magic link to
        <br />
        <span className="font-bold">{email}</span>
      </p>

      <CodeInput className="max-w-xs" codeLength={6} onSubmit={handleCodeSubmit} />

      <Button variant="ghost" onClick={onBack}>
        Change email
      </Button>
    </div>
  )
}

export default CodeStep

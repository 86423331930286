import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import imgIllustrationDashboard from '@/assets/illustration_dashboard.png'
import SignInForm from '@/modules/auth/components/SignInForm/SignInForm'
import useAuth from '@/modules/auth/hooks/useAuth'
import AppLayout from '@/modules/general/components/AppLayout/AppLayout'
import PageHeader from '@/modules/general/components/PageHeader/PageHeader'

const SignInPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect') || '/'

  const navigate = useNavigate()
  const handleSuccess = () => {
    navigate(redirect, { replace: true })
  }

  const { isAuth } = useAuth()
  if (isAuth) {
    return <Navigate to={redirect} replace />
  }

  return (
    <AppLayout
      header={<PageHeader />}
      body={
        <div className="flex flex-grow flex-row items-center justify-center gap-4 px-20 mobile:px-4">
          <img
            src={imgIllustrationDashboard}
            className="mobile:hidden"
            alt="Illustration dashboard"
            width={380}
          />
          <SignInForm
            className="flex w-[340px] shrink-0 flex-col items-center"
            onSignInSuccess={handleSuccess}
            redirect={redirect}
          />
        </div>
      }
    />
  )
}

export default SignInPage

import { useQuery } from '@tanstack/react-query'

import { taskApi } from '@/modules/task/api'

const useTasksStatistics = (fromDate: number, toDate: number) => {
  return useQuery({
    queryKey: ['tasks_statistics', fromDate, toDate],
    queryFn: () => {
      return taskApi.getTasksStatistics(fromDate, toDate)
    },
  })
}

export default useTasksStatistics

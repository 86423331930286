import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { authActions } from '@/modules/auth'
import useAuth from '@/modules/auth/hooks/useAuth'

const SignOutPage: React.FC = () => {
  const { isAuth } = useAuth()

  useEffect(() => {
    if (isAuth) {
      authActions.dropAuth()
    }
  }, [isAuth])

  if (!isAuth) {
    return <Navigate to="/" />
  }

  return <div>...</div>
}

export default SignOutPage

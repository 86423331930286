import { OnsaLogo } from '@onsaui'
import React from 'react'

const PageHeader: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  return (
    <>
      <OnsaLogo />
      <h2 className="ml-8 mobile:text-h3">Revenue Grid Task Statistics</h2>
      <div className="flex-grow" />

      {children}
    </>
  )
}

export default PageHeader

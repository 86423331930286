import { IconClose } from '@onsaui/icons'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import cx from 'clsx'
import React, { useCallback } from 'react'

import cm from './Modal.module.css'

const Modal = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  {
    children: React.ReactNode
    isOpen?: boolean
    onClose?: () => void
    className?: string
  }
>(({ children, isOpen, onClose, className }, ref) => {
  const handleInteractOutside = useCallback(
    (event: Event) => {
      if (event.defaultPrevented) {
        return
      }

      onClose?.()
    },
    [onClose],
  )

  const handleEscapeKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return
      }

      onClose?.()
    },
    [onClose],
  )

  const handleCloseClick = useCallback(
    (event: React.MouseEvent) => {
      if (event.defaultPrevented) {
        return
      }

      onClose?.()
    },
    [onClose],
  )

  return (
    <DialogPrimitive.Root open={isOpen}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={cx('fixed inset-0 z-[100]', cm.overlay)} />
        <div className="fixed inset-0 z-[100] flex flex-col items-center px-4">
          <DialogPrimitive.Content
            className={cx(
              'relative top-[12%] rounded-[32px] bg-level1 px-10 pb-8 pt-8',
              cm.modalContent,
              className,
            )}
            ref={ref}
            onInteractOutside={handleInteractOutside}
            onEscapeKeyDown={handleEscapeKeyDown}
          >
            <DialogPrimitive.Close
              className="absolute right-4 top-4 transition-colors hover:text-primary"
              onClick={handleCloseClick}
            >
              <IconClose />
            </DialogPrimitive.Close>

            {children}
          </DialogPrimitive.Content>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
})
Modal.displayName = 'ModalOverlay'

export { Modal }

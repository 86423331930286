import Cookies from 'js-cookie'

import { useAuthStore } from '@/modules/auth'
import { authApi } from '@/modules/auth/api'

const setAuth = (token: string, userId: string) => {
  Cookies.set('rg_admin_token', token)
  Cookies.set('rg_admin_token_user_id', userId)

  useAuthStore.setState({ token, userId })
}

export const getAuthToken = () => {
  return useAuthStore.getState().token
}

export const getSessionId = () => {
  return useAuthStore.getState().sessionId
}

export const reviewAuth = () => {
  const token = Cookies.get('rg_admin_token')
  const userId = Cookies.get('rg_admin_token_user_id')

  if (token && userId) {
    setAuth(token, userId)
  }
}

export const dropAuth = () => {
  Cookies.remove('rg_admin_token')
  Cookies.remove('rg_admin_token_user_id')

  useAuthStore.setState({ token: null, userId: null })
}

export const authViaCode = async (code: string) => {
  const { accessToken, userId } = await authApi.authViaCode(code)

  setAuth(accessToken, userId)
}

export const authViaToken = (token: string, userId: string) => {
  setAuth(token, userId)
}

export const subscribeOnDropAuth = (callback: () => void) => {
  useAuthStore.subscribe((state, prevState) => {
    if (prevState.token && !state.token) {
      callback()
    }
  })
}

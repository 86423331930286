import React from 'react'

import AppLayout from '@/modules/general/components/AppLayout/AppLayout'
import PageHeader from '@/modules/general/components/PageHeader/PageHeader'
import BaseStatisticsWidget from '@/modules/task/components/BaseStatisticsWidget/BaseStatisticsWidget'
import TasksStatisticsWidget from '@/modules/task/components/TasksStatisticsWidget/TasksStatisticsWidget'
import ProfileMenu from '@/modules/user/components/ProfileMenu/ProfileMenu'

const DashboardPage: React.FC = () => {
  return (
    <AppLayout
      header={
        <PageHeader>
          <ProfileMenu />
        </PageHeader>
      }
      body={
        <div className="flex flex-grow flex-col px-20 py-7 mobile:px-4">
          <BaseStatisticsWidget className="mb-12 self-start" />
          <TasksStatisticsWidget />
        </div>
      }
    />
  )
}

export default DashboardPage
